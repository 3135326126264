.allReviews {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* align-items: center; */
    justify-content: center;
    /* border-bottom: 1px solid black; */
}

.oneReview {
    display: flex;
    flex-direction: column;
    align-items: flex start;
    justify-content:flex-start;
    margin: 2% 2%;
    padding: 2em;
    height: auto;
    width: 30%;
    border-radius: 25px;
    /* max-height: 180px; */
    box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.1);

}

.ratingAndTotalReviews {
    padding: 20px;
    font-size: large;
}

.ratingAndCreate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.crudButton {
    background-color: rgba(199, 14, 14);
    color: white;
    border: 1px solid black;
    padding: 12px;
    /* margin-top: 15px; */
    margin: 8px;
    border-radius: 10px;
    font-size: 16px;
}

.crudButton2 {
    background-color: hsl(0, 0%, 50%, .04);
    border: 1px solid black;
    padding: 12px;
    /* margin-top: 15px; */
    margin: 8px;
    border-radius: 10px;
    font-size: 16px;
}

.reviewName {
    font-size: large;
    font-weight: bold;
    padding: 3px;
}

.reviewDate {
    font-size: large;
    padding: 3px;
}

.buttonAndRating {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.reviewDescrip {
    font-size: 12px;
    word-break: break-word;
    overflow-y: auto;
    height: 100px;
}