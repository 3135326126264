.fullReviewForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0px;
    padding-left:35px;
    padding-right:35px;
    width: 450px;
    height: 265px;
    font-family: 'Times New Roman', Times, serif;

}

.reviewSubmitButton {
    width: 100%;
    height: 50px;
    min-height: 50px;
    border-radius: 2px;
    background-color: rgb(215, 4, 102);
    color: white;
    margin-top: 10px;
    border-radius: 5px;
}


.write-review-title {
    padding-bottom: 18px;
    margin-bottom: 20px;
}

.formInputs {
    display: flex;
    flex-direction: column;
}

.formPadding {
    padding: 4px;
}

.textAreaInput {
    width: 100%;
    height: 100px;
    /* resize: none; */
    border-radius: 10px;
}

#modal-content {
    background-color: white;
    border-radius: 10px;
}

.title {
    margin-left: 35px;
}

.oneReviewError {
    font-size: 10px;
    margin-top: 2px;
}

.reviewErrors {
    display: flex;
    flex-direction: column;
    justify-content:end;
    /* margin-top: 10px; */
}