.fullSpotFormCreateSpot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0px;
    padding-right: 35px;
    padding-bottom: 0px;
    padding-left: 35px;
    margin-bottom: 10px;
    margin-top: 0px;
    width: 550px;
    height: 610px;
    overflow-y: scroll;
}

.spotSubmitButton {
    width: 100%;
    height: 50px;
    min-height: 50px;
    border-radius: 2px;
    background-color: rgb(215, 4, 102);
    color: white;
    margin-top: 10px;
    border-radius: 5px;
}

.formInputs {
    display: flex;
    flex-direction: column;
}

.oneFormInput {
    padding: 4px;
}

.textAreaInputCreate {
    width: 100%;
    height: 25px;
    /* resize: none; */
    border-radius: 4px;
    padding: 4px;
    border: 1px solid black;
}

.formPadding {
    padding: 3px;
}

.actualInput {
    border-radius: 4px;
    border: 1px solid black;
}

.title {
    margin-left: 35px;
    /* margin-bottom:20px */
}

.errors {
    display: flex;
    flex-direction: column;
    justify-content:end;
    /* margin-top: 10px; */
}

.oneError {
    font-size: 10px;
    margin-top: 2px;
}