.end {
    display: flex;
    justify-content: flex-end;
}

.spotCardImg {
    height: 75%;
    width: 100%;
    border-radius: 25px;
}

.spotCard {
    width: 300px;
    height: 300px;
    /* width:20%;
    height: 20%; */
    margin: 18px;
    text-decoration: none;
    color: black;
}

.allSpotCards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    height: 100%;
    min-height: 100%;
}

.spotCardText {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}