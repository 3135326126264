.topBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    border-bottom: 2px solid rgba(192, 191, 191, 0.8);
    margin-bottom: 20px;
    box-sizing: border-box;
}

.imgLogo {
    width: 50px;
    height: 50px;
    margin-left: 20px;
}

.becomeHost {
    background-color: transparent;
    border: none;
    font-weight: bold;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 10px;
    font-size: 15px;
    color:rgba(12, 11, 11, 0.8)
}

.logoAndTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 18px;
}

.titleNav {
    padding-left: 15px;
    color: rgb(8, 2, 2);

}

.coolButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 12px;
    padding-left: 12px;
    margin-right: 10px;
    background-color: white;
}

.profile-dropdown {
    display: flex;
    justify-content: center;
    align-items:flex-start;
    flex-direction: column;
    position: absolute;
    background-color: white;
    height: 75px;
    right: 0;
    width: 200px;
    top: 3rem;
    /* border-radius: 15px; */
    /* box-shadow: 5px 1px 1px rgb(0 0 0 / 10%); */
    /* border: 1px solid black; */
    margin-right: 20px;
    margin-top: 0pz
}

.createAndCool {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    width: 18%;
}

.hamButton1 {
    /* padding-bottom: 5px; */
    padding: 10px;
    background-color: white;
    width: 100%;
    height: 50%;
    border: none;
    text-align: left;
    border-radius: 5px;
    box-shadow: 2px 2px 30px rgb(0 0 0 / 10%);
    font-size: 14px;
}

.hamButton1:hover {
    background-color: rgb(19, 120, 202, .2);
}

.hamButton2 {
    /* padding-top: 5px; */
    padding: 10px;
    background-color: white;
    width: 100%;
    height: 50%;
    text-align: left;
    border: none;
    border-radius: 5px;
    box-shadow: 2px 2px 30px rgb(0 0 0 / 10%);
    font-size: 14px;
}

.hamButton2:hover {
    background-color: rgb(19, 120, 202, .2);
}

.userAndEmail{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3px;
    background-color: white;
    width: 100%;
    box-sizing: border-box;
    height: 50%;
    text-align: left;
    border: none;
    border-radius: 5px;
    box-shadow: 2px 2px 30px rgb(0 0 0 / 10%);
    font-size: 12px;
}

.profile-dropdownLogOut {
    display: flex;
    justify-content: center;
    align-items:flex-start;
    flex-direction: column;
    position: absolute;
    background-color: white;
    height: 75px;
    right: 0;
    width: 200px;
    top: 3.5rem;
    /* border-radius: 15px; */
    /* box-shadow: 5px 1px 1px rgb(0 0 0 / 10%); */
    /* border: 1px solid black; */
    margin-right: 20px;
    margin-top: 0px;
}

.bars {
    padding-right: 5px;
}

.profileFace {
    padding-left: 5px;
}

.username {
    padding-left: 5px;
}

.email {
    padding-left: 5px;
}