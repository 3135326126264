

.gitLogo {
    height: 30px;
    width: 30px;
    padding-top:7px;
    padding-right: 5px;

}

.inLogo {
    height: 30px;
    width: 30px;
    padding-top:7px;
    padding-left: 5px;
}


.homeDiv {
    /* height: 100%;
    width: 100%; */
    box-sizing: border-box;
    min-height: 90%;
}

/* html {
    height: 100vh
}

body {
    height: 100vh
} */

#root {
    height: 100vH;
}
html { height: 100%; }

body {
margin: 0px;
position: relative;
}

.footer {
    display: flex;
    position: sticky;
    bottom: 0;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    border-top: 1px solid grey;
    background-color: white;
    max-height: 42px;
}