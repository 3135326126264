.spotDescriptionTopBorder {
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 20px 50px 20px;
    word-break: break-word;

}

.ratingAndReviewAmount {
    /* border-top: 1px solid grey;
    border-bottom: 1px solid grey; */
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0px 50px 0px;
    font-size: large;

}



.editDeleteSpot {
    display: flex;

    /* margin: 10px 50px 10px; */
    /* padding-top: 10px; */
    padding-bottom: 10px;
    padding-top: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.allOneSpot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 1110px;
    margin: 0 auto
}

.spotTitle {
    font-size: 26px;
}

.location {
    margin-bottom: 10px;
}

/* .imgWidth {
    width: 400px;
    height: 400px;

} */

.allSpotImages {
    width:100%;
    height: 450px
}

.oneSpotImage {
    width: 30%;
    height: 200px;
    padding: 5px;
    border-radius: 5%;
}

.hostingBookingInfo {
    display: flex;
    flex-direction: row;
}

.centerOneSpot {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

}

.booking {
    margin-top: 40px;
    /* border: 1px solid grey; */
    padding: 25px;
    border-radius: 8%;
    box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.1);

}

.bookingText {
    font-size: 16px;
    padding: 4px;
}

.hostingInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
}

.symbolBox {
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.symbolAndText {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 5px;
    align-items: center;
}

.symbols {
display: flex;
flex-direction: column;
align-items: end;
}

.oneSymbol {
padding: 3px;
}

.smallAndBigImgs {
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
width: 100%;
height: 350px;

}

.oneBigImgBox {
    width:40%;
    height: 100%;
}

.oneBigImg {
    width: 100%;
    height: 100%;
    border-radius: 5% 0% 0% 5%;

}
.smallImgsBox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width:60%;
    height: 100%;
}

.smallImgs {
    width: 45%;
    height: 50%;
    padding: 2px;
    border-radius: 10px;
    box-sizing: border-box;
}

.crudButton {
    background-color: rgba(199, 14, 14, .7);
    color: white;
    border: 1px solid black;
    padding: 12px;
    margin: 8px;
    border-radius: 10px;
    font-size: 16px;
}

.crudButton2 {
    background-color: hsl(0, 0%, 50%, .04);
    border: 1px solid black;
    border: 1px solid black;
    padding: 12px;
    margin: 8px;
    border-radius: 10px;
    font-size: 16px;
}


.footer2 {
    display: flex;
    position: sticky;
    bottom: 0;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    border-top: 1px solid grey;
    background-color: white;
    max-height: 42px;
}
